@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
::-webkit-scrollbar {
  width: 0;
}
body {
  font-family: "Poppins", sans-serif !important;
}
@screen lg {
  .feedshadow {
    box-shadow: 2px 2px 20px 0px rgba(233, 231, 231, 0.75),
      -2px -2px 5px 0px rgba(233, 231, 231, 0.75);
    -webkit-box-shadow: 2px 2px 5px 0px rgba(233, 231, 231, 0.75),
      -2px -2px 5px 0px rgba(233, 231, 231, 0.75);
    -moz-box-shadow: 2px 2px 5px 0px rgba(233, 231, 231, 0.75),
      -2px -2px 5px 0px rgba(233, 231, 231, 0.75);
  }
}
.feedshadowall {
  box-shadow: 2px 2px 20px 0px rgba(233, 231, 231, 0.75),
    -2px -2px 5px 0px rgba(233, 231, 231, 0.75);
  -webkit-box-shadow: 2px 2px 5px 0px rgba(233, 231, 231, 0.75),
    -2px -2px 5px 0px rgba(233, 231, 231, 0.75);
  -moz-box-shadow: 2px 2px 5px 0px rgba(233, 231, 231, 0.75),
    -2px -2px 5px 0px rgba(233, 231, 231, 0.75);
}
.popupshadow {
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.205), -2px -2px 8px 0px rgba(0, 0, 0, 0.205);
  -webkit-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.205), -2px -2px 8px 0px rgba(0, 0, 0, 0.205);
  -moz-box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.205), -2px -2px 8px 0px rgba(0, 0, 0, 0.205);
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[name="Gender"] {
  accent-color: black;
}
.otpNumber > input {
  outline: none;
  background-color: transparent;
}
.brandcontactinput > input {
  outline: none;
  background-color: white;
  padding: 10px 10px 10px 0px;
}
.inr {
  font-family: "Roboto", sans-serif !important;
  font-weight: 400 !important;
}
.inrr {
  font-family: "Roboto", sans-serif !important;
}
.inter {
  font-family: "Inter", sans-serif !important;
}

#career_file::file-selector-button {
  border: none;
  padding: 5px 10px;
  margin-right: 10px;
}
.scrollbar::-webkit-scrollbar {
  width: 0;
  background: transparent;
  display: none;
}
.scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Responsive Container Start */
.r-box {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 639px) {
  .r-box {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media only screen and (min-width: 640px) {
  .r-box {
    width: 610px;
  }
}
@media only screen and (min-width: 768px) {
  .r-box {
    width: 720px;
  }
}
@media only screen and (min-width: 1024px) {
  .r-box {
    width: 970px;
  }
}
@media only screen and (min-width: 1280px) {
  .r-box {
    width: 1200px;
  }
}
@media only screen and (min-width: 1536px) {
  .r-box {
    width: 1360px;
  }
}
/* Responsive Container End */
.swiper-pagination-bullet {
  background-color: black !important;
}
.i1,
.i2 {
  width: 25px;
}
.i2 {
  display: none;
}
.navsideicons:hover .i2 {
  display: block;
}
.navsideicons:hover .i1 {
  display: none;
}
.socialcardbg {
  background-image: linear-gradient(145deg, #121212 0%, #000000 100%);
}

.polygon {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: red;
  height: 100%;
  background-image: linear-gradient(to right, black, rgb(78, 77, 77));
  clip-path: polygon(100% 0, 100% 85%, 0 100%, 0 0);
  z-index: -1;
}
@media only screen and (min-width: 768px) {
  .polygon {
    clip-path: polygon(100% 0, 100% 75%, 0 100%, 0 0);
  }
}
@media only screen and (min-width: 1024px) {
  .polygon {
    clip-path: polygon(100% 0, 100% 55%, 0 100%, 0 0);
  }
}
.ranger {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateY(-50%);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  pointer-events: none;
  background: none;
  outline: none;
}
.ranger::-webkit-slider-thumb {
  pointer-events: auto;
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: black;
  border: none;
}
.ranger::-moz-range-thumb {
  pointer-events: auto;
  -moz-appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: black;
  border: none;
}
.ranger::-ms-thumb {
  pointer-events: auto;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: black;
  border: none;
}
.cp > :nth-child(4) {
  fill: transparent !important;
  stroke-width: 1 !important;
  stroke: white;
}
.cp2 > :nth-child(4) {
  fill: transparent !important;
  stroke-width: 1 !important;
  stroke: black;
}
.cp-parent:hover .cp > :nth-child(4) {
  stroke: black;
}
.cp-parent:hover .cp2 > :nth-child(4) {
  stroke: white;
}
.profilephone > input {
  height: 100%;
  border: none;
  outline: none;
}
.selectaccent {
  -moz-appearance: none;
  -webkit-appearance: none;
}
.selectaccent::-ms-expand {
  display: none;
}
.alter .swiper-pagination {
  width: fit-content !important;
  height: 28px !important;
  display: flex !important;
  align-items: center !important;
  bottom: 0 !important;
  right: 72px !important;
  left: auto !important;
}
.alter .swiper-pagination-bullet {
  width: 5px !important;
  height: 5px !important;
}

.home .swiper-pagination {
  width: fit-content !important;
  height: fit-content !important;
  display: flex !important;
  align-items: center !important;
  top: 20px !important;
  left: 20px !important;
  background-color: white;
  padding: 3px;
  border-radius: 20px;
  transform: scaleX(-100%);
}
.home .swiper-pagination-bullet {
  width: 5px !important;
  height: 5px !important;
}
