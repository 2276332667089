.toastMessage{
    animation: toast .3s ease-out forwards;
}
@keyframes toast {
0%  {
      transform: translateY(-100%);
    }
50% {
      transform: translateY(15px);
    }
100%{
      transform: translateY(10px);
    }
}